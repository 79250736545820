<template>
  <div class="laws">
    <!-- 头部 -->
    <app-header @getHeight="getHeight" :phone="phone" />
    <section class="" style="background-color: #fafafa">
      <div class="max_width min_width">
        <div
          class="flex align_center padtb_20"
          :style="{ 'margin-top': offsetHeight + 'px' }"
        >
          <div class="f838 size_14">当前位置：</div>
          <el-breadcrumb separator-class="el-icon-arrow-right">
            <el-breadcrumb-item :to="{ path: '/' }">首页</el-breadcrumb-item>
            <el-breadcrumb-item :to="{ path: '/LawsRegulations' }"
              >政策法规</el-breadcrumb-item
            >

            <el-breadcrumb-item class="d8f">今日申报</el-breadcrumb-item>
          </el-breadcrumb>
        </div>
      </div>
    </section>
    <!-- 标签 -->
    <section class=" " style="background-color: #fafafa">
      <div
        class="max_width min_width "
        style="
          width: 1520px;
          height: 100px;
          line-height: 100px;
          background: #ffffff;
        "
      >
        <div class="flex align_center">
          <div class="fnf size_15 weight text_nowrap marl_12" >关键词搜索：</div>
          <div class="width_50 laws_search">
            <el-input placeholder="请输入关键词" v-model="keyword" @keyup.enter.native="searchData()">
              <i slot="suffix" class="el-icon-search" @click="searchData"></i>
            </el-input>
          </div>
        </div>
      </div>
    </section>

    <section
      class=""
      style="background-color: #fafafa; line-height: 60px; height: 60px"
    >
      <div class="max_width min_width">
        <div style="display: flex">
          <div
            class=""
            style="padding: 0px 50px"
            @click="setsatus(1)"
            :style="status === 1 ? 'background-color:#ff7129;' : ''"
          >
            最新发布
          </div>
          <div
            class=""
            style="padding: 0px 50px"
            @click="setsatus(2)"
            :style="status === 2 ? 'background-color:#ff7129;' : ''"
          >
            即将结束
          </div>
          <div
            class=""
            style="padding: 0px 50px"
            @click="setsatus(3)"
            :style="status === 3 ? 'background-color:#ff7129;' : ''"
          >
            正在申报
          </div>
          <div
            class=""
            style="padding: 0px 50px"
            @click="setsatus(4)"
            :style="status === 4 ? 'background-color:#ff7129;' : ''"
          >
            往期申报
          </div>
        </div>
      </div>
    </section>
    <!-- 列表 -->
    <section class="" style="background-color: #fafafa">
      <div class="max_width min_width">
        <div class="width">
          <div class="bgfff laws_pad">
            <div>
              <div
                class="padtb_15 laws_btm_bor"
                v-for="(item, index) in declares"
                :key="index"
                style="display: flex; justify-content: space-between"
              >
                <div style="flex: 1">
                  <div class="ttt size_15 text_line1 font cursor weight">
                    {{ item.title }}
                  </div>

                  <div class="flex align_center mart_10">
                    <div class="flex align_center">
                      <div class="size_14 ttt">{{ item.source }}</div>
                    </div>
                    <div class="flex align_center">
                      <div class="size_14 eze" style="margin: 0px 10px">
                        {{ item.startTime }} 至 {{ item.endTime }}
                      </div>
                    </div>

                    <div class="flex align_center">
                      <div
                        class="size_14 eze"
                        style="margin: 0px 10px; color: #ffa820"
                      >
                        剩余时间：{{ item.day }}
                      </div>
                    </div>
                  </div>
                </div>
                <!-- v-if="item.type === 1 && status != 4" -->
                <!--   @click="dialogFormVisible = true" -->
                <el-button
                  type="text"
                  v-if="item.type === 1 && status != 4"
                  @click="todeclarationdetails(item.id)"
                  style="
                    width: 130px;
                    height: 40px;
                    background: #0d8eff;
                    border-radius: 6px;
                    color: #fff;
                  "
                >
                线上申报</el-button
                >
                <!-- v-else-if="item.type === 2"  @click="dialogFormVisibles = true" -->
                <el-button
                  v-else-if="item.type === 2"
                  type="text"
                  style="
                    width: 130px;
                    height: 40px;
                    background: #cce7ff;
                    border-radius: 6px;
                    color: #0d8eff;
                    font-weight: 400;
                  "
                  @click="todeclarationdetails(item.id)"
                >
                线下申报</el-button
                >

                <!-- <el-dialog title="立即申报" :visible.sync="dialogFormVisible">
                  <el-form :model="from">
                    <el-form-item label="姓名" :label-width="formLabelWidth">
                      <el-input
                        v-model="from.name"
                        style="width: 200px"
                        autocomplete="off"
                        placeholder="请输入姓名"
                      ></el-input>
                    </el-form-item>
                    <el-form-item label="电话" :label-width="formLabelWidth">
                      <el-input
                        v-model="from.phone"
                        placeholder="请输入电话"
                        style="width: 200px"
                        autocomplete="off"
                      ></el-input>
                    </el-form-item>
                  </el-form>
                  <div slot="footer" class="dialog-footer">
                    <el-button @click="dialogFormVisible = false"
                      >取 消</el-button
                    >
                    <el-button type="primary" @click="getadd(item.id)"
                      >确 定</el-button
                    >
                  </div>
                </el-dialog> -->

                <!-- <el-dialog title="线下申报" :visible.sync="dialogFormVisibles">
                  <el-form :model="look">
                    <el-form-item label="姓名" :label-width="formLabelWidth">
                      <el-input
                        v-model="look.name"
                        style="width: 200px"
                        autocomplete="off"
                        placeholder="请输入姓名"
                        readonly="readonly"
                      ></el-input>
                    </el-form-item>
                    <el-form-item label="电话" :label-width="formLabelWidth">
                      <el-input
                        v-model="look.phone"
                        placeholder="请输入电话"
                        style="width: 200px"
                        autocomplete="off"
                        readonly="readonly"
                      ></el-input>
                    </el-form-item>
                  </el-form>
                  <div slot="footer" class="dialog-footer">
                    <el-button @click="dialogFormVisibles = false"
                      >取 消</el-button
                    >
                    <el-button
                      type="primary"
                      @click="dialogFormVisibles = false"
                      >确 定</el-button
                    >
                  </div>
                </el-dialog> -->

                <!-- <div 
                  v-else-if="item.type === 2"
                  style="
                    width: 130px;
                    height: 40px;
                    background: #0d8eff;
                    border-radius: 6px;
                    text-align: center;
                    line-height: 40px;
                  "
                >
                  线下申报
                </div> -->

                <el-button
                  v-if="item.type === 1 && status === 4"
                  @click="open4"
                  style="
                    width: 130px;
                    height: 40px;
                    background: #ed6f00;
                    border-radius: 6px;
                    color: rgb(255, 255, 255);
                  "
                  >申报结束</el-button
                >
              </div>
            </div>

            <el-empty
              description="暂无数据"
              v-if="empty"
              :image-size="250"
            ></el-empty>
          </div>

          <!-- <div class="laws_w30">
            <img src="../../assets/image/17.png" class="laws_w30_pic" />
            <img src="../../assets/image/18.png" class="mart_20 laws_w30_pic" />
          </div> -->
        </div>
      </div>
    </section>

    <!-- 分页 -->
    <el-pagination
      v-if="!empty"
      @size-change="handleSizeChange"
      @current-change="handleCurrentChange"
      :current-page="paginations.page_index"
      :page-sizes="paginations.page_sizes"
      :page-size="paginations.page_size"
      :layout="paginations.layout"
      :total="paginations.total"
    >
    </el-pagination>

    <!-- 尾部 -->
    <app-footer @phone="getPhone" />
  </div>
</template>

<script>
import Header from "@/components/Header/header.vue";
import Footer from "@/components/Footer/footer.vue";
// import MyPaginations from "@/components/MyPaginations/mypaginations.vue";

export default {
  data() {
    return {
      paginations: {
        page_index: 1, // 当前位于哪页
        total: 10, // 总数
        page_size: 10, // 1页显示多少条
        page_sizes: [10, 20, 50, 90], //每页显示多少条
        layout: "total, sizes, prev, pager, next, jumper", // 翻页属性
      },
      declares: [],
      alldeclares: [],
      remainingtime: "",
      listsItem: [],
      flag: true,
      //当前身份
      identity: localStorage.logintype,
      // 状态
      status: 1,
      // 栏目类别
      category: "",
      // 文件级别
      file_level: "",
      // 所属区域
      area: "",
      // 发文部门
      source: "",
      // 政策类型
      policy_type: "",
      // 行业类型
      industry: "",
      // 所属年份
      year: "",
      keyword: "",
      first: [],
      second: [],
      third: [],
      four: [],
      five: [],
      six: [],
      sevent: [],
      policyList: [],
      // 总数
      count: 0,
      empty: false,
      offsetHeight: 0,
      phone: "",
      formLabelWidth: "60px",
      dialogFormVisible: false,
      dialogFormVisibles: false,
      validate: 0,
      total:""
      // from: {
      //   name: "",
      //   phone: "",
      // },
      // look: {
      //   name: "",
      //   phone: "",
      // },
    };
  },
  components: {
    "app-header": Header,
    "app-footer": Footer,
    // "app-mypaginations": MyPaginations,
  },
  methods: {
    //申报
    declare(id) {
      console.log(this.identity, "this.identity");
      if (this.identity == 1) {
        let data = {
          declares_id: id,
          token: localStorage.eleToken,
        };
        console.log(data, "data");
        this.$get("/declares_validate", data).then((res) => {
          // this.validate = result;

          console.log("re11111111s");
          console.log(res, "res");
          if (res.status === 1) {
            this.dialogFormVisible = true;
          }
        });
      } else {
        this.$message({
          message: "仅允许企业操作",
          type: "error",
          duration: 1500,
        });
      }
    },
    open4() {
      this.$message.error("申报已结束");
    },
    setsatus(index) {
      this.status = index;
      this.getdeclareslist();
    },
    getPhone(phone) {
      this.phone = phone;
    },
    // 获取头部高度
    getHeight(data) {
      this.offsetHeight = data;
    },
    // 设置标签以及筛选
    setTag(name, id = "") {
      this[name] = id;
      this.paginations.page_index = 1;

      this.policyList = [];

      this.getdeclareslist();
    },
  
    handleCurrent(item) {
      this.listsItem = item;
    },
    handleSize(item) {
      this.listsItem = item;
    },

    searchData() {
      this.paginations.page_index = 1;
      this.policyList = [];

      this.getdeclareslist();
    },
    // 今日申报列表
    getdeclareslist() {
      // let data = {
      //   page: this.paginations.page_index,
      //   limit: this.paginations.page_size,
      //   category: this.category,
      //   file_level: this.file_level,
      //   area: this.area,
      //   source: this.source,
      //   policy_type: this.policy_type,
      //   industry: this.industry,
      //   year: this.year,
      //   keyword: this.keyword,
      // };

      // this.$post("/policy_list", data).then((res) => {
      //   (this.policyList = res.result ? res.result : []),
      //     (this.count = res.count);

      //   if (this.paginations.page_index == 1 && this.policyList.length == 0) {
      //     this.empty = true;
      //   } else {
      //     this.empty = false;
      //   }

      //   this.resetPaginations();
      // });
      let data = {
        keyword: this.keyword ? this.keyword : "",
        limit: this.paginations.page_size,
        status: this.status,
        page: this.paginations.page_index,
      };
      this.$post("/declares_list", data).then((res) => {
        console.log(res, "res");
        this.alldeclares = res.result;
        this.total = res.count;
        if (this.paginations.page_index == 1 && this.alldeclares.length == 0) {
          this.empty = true;
        } else {
          this.empty = false;
        }

        this.setPaginations();
      });
    },

    // 分页

    handleSizeChange(page_size) {
      console.log(page_size, "page_size");
      this.paginations.page_index = 1;
      this.paginations.page_size = page_size;

      this.declares = this.alldeclares.filter((item, index) => {
        return index < page_size;
      });
    },

    handleCurrentChange(page) {
      console.log(page, "handleCurrentChangepage");
      //获取当前页
      let index = this.paginations.page_size * (page - 1);
      //获取数据总数
      let nums = this.paginations.page_size * page;
      //容器
      let tables = [];
      for (let i = index; i < nums; i++) {
        if (this.alldeclares[i]) {
          tables.push(this.alldeclares[i]);
        }
        this.declares = tables;
      }
    },

    //重置分页
    setPaginations() {
      // 总页数
      this.paginations.total = this.total;
      this.paginations.page_index = 1;
      this.paginations.page_size = 10;
      console.log(this.alldeclares, "this.alldeclares");
      //设置默认的分页数据
      if (this.alldeclares.length != 0) {
        this.declares = this.alldeclares.filter((item, index) => {
          return index < this.paginations.page_size;
        });
      } else {
        this.declares = [];
      }
    },

    // getadd(id) {
    // console.log(this.from.name, this.from.phone, id);
    // let data = {
    //   token: localStorage.eleToken,
    //   declares_id: id,
    //   contact: this.from.name,
    //   mobile: this.from.phone,
    // };
    // console.log(data, "data");
    // this.$post("/declares_user_add", data).then((res) => {
    //   console.log(res, "res");
    //   this.$message({
    //     type: "success",
    //     message: res.msg,
    //   });
    //   this.dialogFormVisible = false;
    // });
    // },

    // 跳转今日申报详情
    todeclarationdetails(id) {
      this.$router.push({
        path: "/declarationdetails",
        query: { ids: id, sta: this.status },
      });
    },
  },
  mounted() {
    if (this.$route.query.ids) {
      this.category = this.$route.query.ids;
    }

    if (this.$route.query.keyword) {
      this.keyword = this.$route.query.keyword;
    }

    this.getdeclareslist();
  },
  created() {
    this.setPaginations();
    this.keyword = this.$route.query.search;
  },
};
</script>

<style scoped>
@import url("./declaration.css");

::v-deep .el-pagination {
  text-align: center;
  padding: 20px 0px;
  background-color: #fafafa;
}
</style>
